<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <validation-observer ref="simpleRules">
      <div>
        <div class="card">
          <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
            <h3 class="font-weight-bolder">
              {{ $t('points') }}
            </h3>

            <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="$t('search')"
                      class="anan-input__input"
                      @keyup.enter="getData(search_val)"
                    >
                    <div class="anan-input__suffix">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getData(search_val)"
                      >
                        <i class="fal fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <b-form-select
                v-model="selected"
                :options="typeOpsearch"
                class="w-50 ml-1"
                size="sm"
              />
              {{ selected }} -->
            </div>
          </div>
          <div class="px-2 pt-1">
            <button
              type="button"
              class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
              @click="showAddNew()"
            >
              <span>+ {{ $t('addNewItem') }} </span>
            </button>
          </div>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <!-- :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn" -->
              <template #cell(number)="data">

                <b-link @click="EditType(data.item)">
                  {{ data.item.itemnumber_text }}
                </b-link>
              </template>
              <template #cell(username)="data">
                <b-link :to="{ name: 'admin-employee-edit', params: { id: data.item.uid } }">
                  {{ data.item.username }}
                </b-link>
              </template>
              <template #cell(type_text)="data">
                <span v-if="data.item.type == 18">{{ data.item.subtype_text }}</span>
                <span v-if="data.item.type == 19">{{ data.item.subtype_text }}</span>
                <span v-else>{{ data.item.type_text }}</span>
                <!-- <span class="text-primary"> {{ data.item.username }}</span> -->
              </template>
              <template #cell(created_at)="data">
                {{ time(data.item.created_at) }}
              </template>
              <template #cell(details)="data">
                <span class="text-primary"> {{ data.item.details }}</span>
              </template>
              <template #cell(amount)="data">
                <span :style="getAmountStyle(data.item.type)">
                  {{ getAmountPrefix(data.item.type) }} {{ Commas(data.item.amount) }} p
                </span>
              </template>
              <template #cell(point_all)="data">
                {{ Commas(data.item.points) }} p
              </template>
              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="EditType(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData(search_val)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>

        <b-modal
          id="modal-add-new"
          :title="$t('key-33')"
          :ok-title="$t('save')"
          :cancel-title="$t('cancel')"
          @ok.prevent="submitAddNew()"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Customer Code"
              rules="required"
            >
              <label for="customerCode">{{ $t('customerCode') }} : <span class="text-danger">*</span></label>
              <v-select
                v-model="username_cus"
                label="username"
                :options="cuslist"
              />
              <!-- :reduce="username => username.username" -->
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Type"
              rules="required"
            >
              <label for="type">{{ $t('type') }} : <span class="text-danger">*</span></label>
              <v-select
                v-model="type"
                :options="typeOp"
                label="text"
              />
              <!-- label="text" -->
              <!-- :state="errors.length > 0 ? false:null" -->
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="detail">{{ $t('details') }} :</label>
            <b-form-textarea
              id="detail"
              v-model="detail"
              :placeholder="$t('details')"
            />
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Amount"
              rules="required"
            >
              <label for="amount">{{ $t('quantity') }} : <span class="text-danger">*</span></label>
              <b-form-input
                id="amount"
                v-model="amount"
                type="number"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('point')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="prefix">Prefix :</label>
            <b-form-input
              id="prefix"
              v-model="prefix"
              type="text"
              placeholder="Prefix"
            />
          </b-form-group>

          <b-form-group>
            <label for="prefixNum">Prefix Number :</label>
            <b-form-input
              id="prefixNum"
              v-model="prefixnumber"
              type="number"
              placeholder="Prefix Number"
            />
          </b-form-group>
        </b-modal>
      </div>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BTable, BLink,
  // BFormSelect,
  BModal, BFormGroup, BFormInput, BFormTextarea, BPagination, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment-timezone'

export default {
  components: {
    BTable,
    BLink,
    // BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: '',
      customerCode: '',
      prefix: '',
      prefixNum: '',
      search: '',
      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,

      items: [],
      showOver: false,
      cuslist: [],
      id: null,
      username_cus: '',
      prefixnumber: '',
      search_val: null,
    }
  },
  computed: {
    typeOp() {
      return [
        { values: 1, text: this.$t('topUp') },
        { values: 2, text: this.$t('withdraw') },
        { values: 3, text: this.$t('key-287') },
        { values: 4, text: this.$t('key-179') },
        { values: 5, text: this.$t('key-180') },
        { values: 6, text: this.$t('mall') },
        { values: 7, text: this.$t('DepositTaobao') },
        // { values: 8, text: 'ค่า Commission' },
        { values: 9, text: this.$t('topupAlipay') },
        { values: 10, text: this.$t('transferToChina') },
        { values: 11, text: this.$t('reportMissing') },
        { values: 12, text: this.$t('key-288') },
        { values: 13, text: this.$t('domesticDeliveryFee') },
        { values: 14, text: this.$t('key-289') },
        { values: 15, text: this.$t('sendToChina') },
        { values: 16, text: this.$t('redeemPoints') },
        { values: 17, text: this.$t('other') },
      ]
    },
    typeOpsearch() {
      return [{ values: '', text: this.$t('all') },
        { values: 1, text: this.$t('topUp') },
        { values: 2, text: this.$t('withdraw') },
        { values: 3, text: this.$t('key-287') },
        { values: 4, text: this.$t('key-179') },
        { values: 5, text: this.$t('key-180') },
        { values: 6, text: this.$t('mall') },
        { values: 7, text: this.$t('DepositTaobao') },
        // { values: 8, text: 'ค่า Commission' },
        { values: 9, text: this.$t('topupAlipay') },
        { values: 10, text: this.$t('transferToChina') },
        { values: 11, text: this.$t('reportMissing') },
        { values: 12, text: this.$t('key-288') },
        { values: 13, text: this.$t('domesticDeliveryFee') },
        { values: 14, text: this.$t('key-289') },
        { values: 15, text: this.$t('sendToChina') },
        { values: 16, text: this.$t('redeemPoints') },
        { values: 17, text: this.$t('other') },
      ]
    },
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'type_text', label: this.$t('type'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'details', label: this.$t('details'), sortable: false, thStyle: { width: '30%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'point_all', label: this.$t('remainingBalance'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData(this.search_val)
    this.getCuslist()
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.id) {
            const obj = {
              id: this.id,
              uid: this.username_cus.uid,
              username: this.username_cus.username,
              details: this.detail,
              type: this.type.values,
              type_text: this.type.text,
              amount: this.amount,
              prefix: this.prefix,
              prefixnumber: this.prefixnumber,
            }
            this.$http.post('/points/StoreUpdate', obj)
              .then(() => {
                this.getData()
                this.Success('บันทึกเรียบร้อย')
              })
              .catch(error => {
                this.SwalError(error)
              })
          } else {
            const obj = {
              uid: this.username_cus.uid,
              username: this.username_cus.username,
              details: this.detail,
              type: this.type.values,
              type_text: this.type.text,
              amount: this.amount,
              prefix: this.prefix,
              prefixnumber: this.prefixnumber,
            }
            this.$http.post('/points/StoreUpdate', obj)
              .then(() => {
                this.getData()
                this.Success('บันทึกเรียบร้อย')
              })
              .catch(error => {
                this.SwalError(error)
              })
          }

          // const params = {
          //   customerCode: this.customerCode,
          //   type: this.type,
          //   detail: this.detail,
          //   amount: this.amount,
          //   prefix: this.prefix,
          //   prefixNum: this.prefixNum,
          // }
          // this.$http.post('groupbyuser/list/create', params).then(() => {
          //   this.getData(this.isActive)
          //   this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
          //     title: 'สำเร็จ',
          //     variant: 'success',
          //     solid: true,
          //     autoHideDelay: 1500,
          //   })
          // })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAddNew() {
      this.id = null
      this.username_cus = null
      this.type = null
      this.detail = null
      this.amount = null
      this.prefix = null
      this.prefixnumber = null
      this.$bvModal.show('modal-add-new')
    },
    // eslint-disable-next-line camelcase
    async getData(search_val) {
      this.showOver = true
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          search_val,
        }
        const { data: res } = await this.$http.get('/points/getData', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    async getCuslist() {
      try {
        const { data: res } = await this.$http.get('/points/getUserpoint')
        this.cuslist = res
        // console.log(res)
      } catch (e) {
        console.log(e)
      }
      // this.$http
      //   .get('/points/getUserpoint')
      //   .then(response => {
      //     this.cuslist = response.data
      //     console.log(this.cuslist)
      //   })
      //   .catch(error => console.log(error))
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.id = data._id
      // eslint-disable-next-line eqeqeq
      if (data.type == 18) {
        this.type = { values: data.sub_type, text: data.subtype_text }
      } else {
        this.type = { values: data.type, text: data.type_text }
      }

      this.username_cus = { username: data.username, uid: data.uid }
      this.detail = data.details
      this.amount = data.amount
      this.prefix = data.prefix
      this.prefixnumber = data.prefixnumber
      this.$bvModal.show('modal-add-new')
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [16, 18].includes(amount)) {
          return { color: 'green' }
        }
        if (amount && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19].includes(amount)) {
          return { color: 'red' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19].includes(value)) {
          return '-'
        }
        if (value && [16, 18].includes(value)) {
          return '+'
        }
      }
      return ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
